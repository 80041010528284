export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <video loop controls autoplay muted className='img-responsive'>
              <source src="img/video.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
            {/*<img src='img/14d.jpg' className='img-responsive' alt='' />*/}{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>A proposito di me</h2>
              <p>{props.data ? props.data.paragraph : 'loading...'}</p>
              <h3>Perché sceglierci?</h3>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
