import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

export const Gallery = (props) => {
  const numberImages = 29,
      images = [];
  for (let i = 0; i < numberImages; i++) {
    images.push({src: `img/importati/` + (i < 10 ? '0' + i : i) + `d.jpg`})
  }
  return (<Carousel autoPlayInterval={3000} images={images} style={{ height: 800 }} />);
  }
