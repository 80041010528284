export const Orario = (props) => {
  return (
    <div id='orario'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            {' '}
            <h3>ORARIO</h3>
          </div>
        </div>
        <div className='row'>
          <div className="col-md-6 orario-table">
            {' '}
            <table>
              <tbody>
                <tr>
                  <th>
                    DAL MARTEDI' AL SABATO
                  </th>
                </tr>
                <tr>
                  <th>
                    MATTINO 8:30/12:00
                  </th>
                </tr>
                <tr>
                  <th>
                    POMERIGGIO 14:30/19:00
                  </th>
                </tr>
                <tr>
                  <th>
                    DOMENICA E LUNEDI' CHIUSO
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
