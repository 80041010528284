export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
        <div className='container'>
        <div className='row'>
          <div className='col-md-8 col-md-offset-2 intro-background'>
            <h1>
              &nbsp;
              <span></span>
            </h1>
            <p>&nbsp;</p>
            {' '}
          </div>
        </div>
        </div>
        </div>
      </div>
      
        <div className='row'>
          <div className='col-md-8 col-md-offset-2 intro-text'>
            <h1>
              {props.data ? props.data.title : 'Loading'}
              <span></span>
            </h1>
            <p>{props.data ? props.data.paragraph : 'Loading'}</p>
            {' '}
          </div>
        </div>
    </header>
  )
}
